import React, { useEffect } from "react";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "photoswipe/dist/photoswipe.css";
import "aos/dist/aos.css";
import { Helmet } from "react-helmet";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  return (
    <>
      <Helmet>
        <title>Dominik Süßmilch - Softwareentwicklung</title>
        <meta name='description' content="App- & Webentwicklung mit kundenorientiertem Design und strukturiertem Ablauf "/>
        <meta property="og:site_name" content="Dominik Süßmilch - Softwareentwicklung" />
        <meta property="og:locale" content="de_DE" />
        <meta
          name="keywords"
          content="Kirchheim unter Teck, Webentwicklung, App-Entwicklung, Mobile App-Entwicklung, Webdesign, Softwareentwicklung, Frontend-Entwicklung,Backend-Entwicklung, React, JAVA, Webentwicklung Kirchheim unter Teck, Softwareentwicklung Kirchheim unter Teck"
        />
      </Helmet>
      {/* End React Helmet for SEO */}

      <AnimatedCursor
        innerSize={8}
        outerSize={40}
        outerAlpha={0.3}
        color='238, 136, 63'
        innerScale={0.7}
        outerScale={2}
      />
      {/* End Animated Cursor */}

      <ScrollToTop />
      {/* End Scroll To Top */}

      <AllRoutes />
      {/* End All Routes */}
    </>
  );
};

export default App;
