import React from "react";

const PersonalInfo = () => {
  return (
    <>
      {/* <!--Animated Block--> */}
      <div className="ptf-animated-block" data-aos="fade" data-aos-delay="100">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">
          Über mich
        </h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "2.8125rem" }}></div>
        <p
          className="fz-24 has-black-color has-secondary-font"
          style={{ maxWidth: "15.3125rem" }}
        >
          Hey, ich bin Dominik, ein erfahrener Softwareentwickler aus Stuttgart, spezialisiert auf innovative Web- und App-Lösungen
          <br/> 
        </p>
      </div>
      {/* End .ptf-animated-block */}

      {/* <!--Spacer--> */}
      <div
        className="ptf-spacer"
        style={{
          "--ptf-xxl": "5.625rem",
          "--ptf-md": "2.8125rem",
        }}
      ></div>

      {/* <!--Animated Block--> */}
      <div className="ptf-animated-block" data-aos="fade" data-aos-delay="200">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">Kontakt</h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "2.8125rem" }}></div>
        <p className="fz-24 has-black-color has-secondary-font">
          Stuttgart, Deutschland <br />
          <a href="mailto:me@dsuessmilch.de">me@dsuessmilch.de</a>
          <br />
          <a href="tel:+4917622988892">+49(0) 176 22988892</a>
        </p>
      </div>
      {/* End .ptf-animated-block */}

      {/* <!--Spacer--> */}
      <div
        className="ptf-spacer"
        style={{
          "--ptf-xxl": "5.625rem",
          "--ptf-md": "2.8125rem",
        }}
      ></div>

      {/* <!--Animated Block--> */}
      <div className="ptf-animated-block" data-aos="fade" data-aos-delay="300">
        <h5 className="fz-14 text-uppercase has-3-color fw-normal">Services</h5>
        {/* <!--Spacer--> */}
        <div className="ptf-spacer" style={{ "--ptf-xxl": "2.8125rem" }}></div>
        <p className="fz-24 has-black-color has-secondary-font">
          Website Design & Entwicklung <br />
          Appentwicklung
          <br />
          sonstige Softwarentwicklung
        </p>
      </div>
      {/* End .ptf-animated-block */}
    </>
  );
};

export default PersonalInfo;
