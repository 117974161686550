import React from "react";

const resumeContent = [
  {
    header: "Schule",
    delayAnimation: "",
    infoContent: [
      {
        year: "2014 - 2016",
        title: "Fachhochschulreife Informatik",
        subTitle: "Max-Eyth-Schule Kirchheim unter Teck",
        learned: "C# - C++ - Java - Datenbanken",
      },
      {
        year: "2019 - Heute",
        title: "Studium Wirtschaftsinformatik (B.Sc.)",
        subTitle: "Hochschule Reutlingen",
        learned: "SQL - Springboot - React - HTML - CSS - Figma",
      },
    ],
  },
  {
    header: "Berufserfahrung",
    delayAnimation: "",
    infoContent: [
      {
        year: "2016 - 2019",
        title: "Ausbildung zum Fachinformatiker Systemintegration",
        subTitle: "Wala Heilmittel Gmbh, Bad BOll",
        learned: "PowerShell - REST API - .NET Framework",
      },
      {
        year: "2020 - Heute",
        title: "Werkstudent im Bereich Entwicklungscontrolling",
        subTitle: " Mercedes-Benz AG, Sindelfingen",
        learned: "Windows Server - PowerShell - Power BI - Power Apps",
      },
      {
        year: "2020 - 2024",
        title: "Gründer und Head of Development",
        subTitle: "ESBIX UG (haftungsbeschränkt), Stuttgart",
        learned: "React Native - React - Laravel - Django - Firebase",
      },
    ],
  },
  {
    header: "Projekte",
    delayAnimation: "",
    infoContent: [
      {
        year: "2022",
        title: "Access Datenbank Entwicklung und Projektleitung",
        subTitle: "MBition Sofia",
        learned: "Access Datenbank - Projektleitung",
      },
      {
        year: "2022",
        title: "Bäckerei Sauter APP",
        subTitle: "Bäckerei Sauter, Stuttgart",
        learned: "React Native - React - Firebase",
      },
      {
        year: "2021 - 2024",
        title: "Diverse Webseiten",
        subTitle: "Weltweit",
        learned: "React - Laravel - Strapi",
      },
    ],
  },
];

const Resume = () => {
  return (
    <>
      {resumeContent.map((item, i) => (
        <div className="col-12 col-md-6 col-lg-4" key={i}>
          {/* <!--Animated Block--> */}
          <div
            className="ptf-animated-block"
            data-aos="fade"
            data-aos-delay={item.delayAnimation}
          >
            {/* <!--Timeline--> */}
            <div className="ptf-timeline">
              <h4 className="ptf-timeline__header">{item.header}</h4>
              <ul className="ptf-timeline__list">
                {item.infoContent.map((val, i) => (
                  <li className="ptf-timeline__item" key={i}>
                    <div className="ptf-timeline__year">{val.year}</div>
                    <h4 className="ptf-timeline__title">{val.title}</h4>
                    <p className="ptf-timeline__description">{val.subTitle}<br/>{val.learned}</p>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Resume;
